import styles from "./Testimonial.module.css"
import Review from "./Review/Review"

const Testimonial = () => {
  return (
    <div className={styles.main_container}>
        
        <div className={styles.heading}>
            <h2>TESTIMONIALS & REVIEWS</h2>
        </div>

        <div className={styles.review}>
        <Review name ="SATYAM SINGH" logo="S" rating={5} clintImg="/Images/Testimonial/satyam.png" reviewmsg="I recently worked with Byte Billion for software support and intern assistance on a project, and I am thoroughly impressed. Their team was responsive, knowledgeable, and provided tailored solutions that exceeded our expectations. The interns demonstrated remarkable skill and dedication, contributing significantly to our project's success. I highly recommend Byte Billion for their exceptional service and talented team."/>
        <Review name = "KAROMANAGE TEAM" logo="K" rating={5} clintImg="/Images/Testimonial/karomanage.png" reviewmsg="Working with Bytebiilion was exceptional. Their expertise and dedication transformed our website, enhancing functionality and user experience significantly."/>
        {/* <Review/> */}
        {/* <Review/> */}

        </div>
    </div>
  )
}

export default Testimonial;