import React from 'react'
import styles from './Project.module.css'
import Slidercomp from './Slidercomp'

const Projects = () => {
   
      
  return (
    <div className={styles.container_project}>
      <div className={styles.content_project}>
        <h1 className={styles.h1_project}>Services</h1>
        <p className={styles.p_project}>Bytebillion takes pride in its developer's expertise in various programming languages, frameworks, tools, libraries, and technologies, enabling us to create exceptional IT solutions for web and mobile applications.</p>

      </div>

        <div  className={styles.comp_project}>
           
         <Slidercomp/>

           
        </div>
    </div>
  )
}

export default Projects