import styles from './Learnhow.module.css'
import {Link} from 'react-router-dom'
const Learnhow = () => {
  return (
    <>
    <div className={styles.paralaxBox}>
    <div className={styles.container}>
     <div className={styles.shadow}></div>
     <div className={styles.overlay}>
      <h2 className={styles.heading}>Learn How Bytebillion Team Augmentation Services Can Accelerate Your Project Success Cost-Effectively As per Business Needs.</h2>
      <button className={styles.btn}><Link to={'/startproject' } className={styles.btnlink_learn} >Start Project</Link></button>
     </div>
    </div>
    </div>
    </>
  )
}

export default Learnhow;