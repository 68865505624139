import styles from "./Review.module.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Review = (props) => {
  useEffect(() => {
    AOS.init({ duration: "1500" });
  }, []);

  
  // this function for render stars
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<img key={i} src="/Images/Testimonial/Star.png" alt="Clint Rating" />);
    }
    return stars;
  };



  return (
    <div className={styles.review_single_box} data-aos="zoom-out">
      <div className={styles.clint_name}>
        <h4>{props.name}</h4>
      </div>
      <div className={styles.review_ratingstar}>
        {renderStars(props.rating)}
      </div>
      <div className={styles.clint_text}>
        <p>{props.reviewmsg}</p>
      </div>
      <div className={styles.masks_group}>
        <div className={styles.clint_img}>
          <img src={props.clintImg} alt="clint" />
        </div>
        <div className={styles.clint_logo}>{props.logo}</div>
      </div>
    </div>
  );
};

export default Review;