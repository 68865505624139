import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import styles from "./Team.module.css";

const details = [
  { src: "/Images/Team/swati.png", alt: "swati", name: "Swati Patidar", role: "Software Developer" },
  { src: "/Images/Team/yash.png", alt: "yash", name: "Yash Bais", role: "Software Developer" },
  { src: "/Images/Team/aditya.png", alt: "aditya", name: "Aditya Panwar ", role: "Software Developer" },
  { src: "/Images/Team/vimal.png", alt: "vimal", name: "Vimal Chouhan", role: "Software Developer" },
  { src: "/Images/Team/narendra.png", alt: "Narendra", name: "Narendra Patidar", role: "Software Developer" },
  { src: "/Images/Team/vaidik.png", alt: "Vaidik", name: "Vaidik Chouhan", role: "Software Developer" },
  { src: "/Images/Team/shruti.png", alt: "Shruti", name: "Shruti Solanki", role: "UI/UX Designer" },
  { src: "/Images/Team/kanhaiya.png", alt: "Kanhaiya", name: "Kanhaiya Patidar", role: "Software Developer" },
  { src: "/Images/Team/jiganya.png", alt: "Jiganya", name: "Jiganya Gupta", role: "Software Developer" },
  { src: "/Images/Team/shivangi.png", alt: "Shivangi", name: "Shivangi Bangar", role: "UI/UX Designer" },
  { src: "/Images/Team/AnkitKumarMeena.png", alt: "Ankit Kumar Meena", name: "Ankit Kumar Meena", role: "Software Developer" },
  { src: "/Images/Team/RaghavMaheshwari.png", alt: "Raghav Maheshwari", name: "Raghav Maheshwari", role: "Software Developer" },
  { src: "/Images/Team/AjayYadav.png", alt: "Ajay Yadav", name: "Ajay Yadav", role: "Quality Analyst" },
  { src: "/Images/Team/UmeshPatidar.png", alt: "Umesh Patidar", name: "Umesh Patidar", role: "Software Developer" },
  { src: "/Images/Team/SiddharthGoswami.png", alt: "Siddharth Goswami", name: "Siddharth Goswami", role: "Software Developer" },
  { src: "/Images/Team/SandeepSolanki.png", alt: "Sandeep Solanki", name: "Sandeep Solanki", role: "Software Developer" },
];

const detailsrespo = [
  { src: "/Images/Team/swati.png", alt: "swati", name: "Swati Patidar", role: "Software Developer" },
  { src: "/Images/Team/yash.png", alt: "yash", name: "Yash Bais", role: "Software Developer" },
  { src: "/Images/Team/aditya.png", alt: "aditya", name: "Aditya Panwar ", role: "Software Developer" },
  { src: "/Images/Team/vimal.png", alt: "vimal", name: "Vimal Chouhan", role: "Software Developer" },
  { src: "/Images/Team/narendra.png", alt: "Narendra", name: "Narendra Patidar", role: "Software Developer" },
  { src: "/Images/Team/vaidik.png", alt: "Vaidik", name: "Vaidik Chouhan", role: "Software Developer" },
  { src: "/Images/Team/shruti.png", alt: "Shruti", name: "Shruti Solanki", role: "UI/UX Designer" },
  { src: "/Images/Team/kanhaiya.png", alt: "Kanhaiya", name: "Kanhaiya Patidar", role: "Software Developer" },
  { src: "/Images/Team/jiganya.png", alt: "Jiganya", name: "Jiganya Gupta", role: "Software Developer" },
  { src: "/Images/Team/shivangi.png", alt: "Shivangi", name: "Shivangi Bangar", role: "UI/UX Designer" },
  { src: "/Images/Team/AnkitKumarMeena.png", alt: "Ankit Kumar Meena", name: "Ankit Kumar Meena", role: "Software Developer" },
  { src: "/Images/Team/RaghavMaheshwari.png", alt: "Raghav Maheshwari", name: "Raghav Maheshwari", role: "Software Developer" },
  { src: "/Images/Team/AjayYadav.png", alt: "Ajay Yadav", name: "Ajay Yadav", role: "Quality Analyst" },
  { src: "/Images/Team/UmeshPatidar.png", alt: "Umesh Patidar", name: "Umesh Patidar", role: "Software Developer" },
  { src: "/Images/Team/SiddharthGoswami.png", alt: "Siddharth Goswami", name: "Siddharth Goswami", role: "Software Developer" },
  { src: "/Images/Team/SandeepSolanki.png", alt: "Sandeep Solanki", name: "Sandeep Solanki", role: "Software Developer" },
];

const Team = () => {
  useEffect(() => {
    AOS.init({ duration: "1300" });
  }, []);
return (
    <>
      <div className={styles.team__main_box}>
        <div className={styles.team__child_box}>
          <div className={styles.team__left}>
            <h1 className={styles.team__left_heading} data-aos="zoom-in" data-aos-delay="200">Our Team</h1>
            <div className={styles.team__lines}></div>
          </div>
          <div className={styles.team__right}>
            <div className={styles.team__column}>
              {details.map((member, index) => (
                <div key={index} className={styles.team__box}>
                  <div className={styles.team__photo}>
                    <img className={styles.team__bigimg} src={member.src} alt={member.alt} />
                  </div>
                  <div className={styles.team__about}>
                    <p className={styles.team__name}>{member.name}</p>
                    <p className={styles.team__role}>{member.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>




      <div className={styles.team_respo_body}>
            <h3 className={styles.team_respo_mainheading}>Our Team</h3>  
    
              <div className={styles.team_respo_container}>
                {detailsrespo.map((member, index) => (
                    <div key={index} className={styles.team_respo_slide_container}>
                      <div className={styles.team_respo_slide_image}>
                        <img className={styles.team_respo_image} src={member.src} alt={member.alt} />
                        
                      </div>
                
                      <div className={styles.team_respo_about}>
                        <p className={styles.team_respo_name}>{member.name}</p>
                        <h5 className={styles.team_respo_role}>{member.role}</h5>
                      </div>
                    </div>
    
                    
    
                  ))}
                </div>
              </div>
    </>
  );
};

export default Team;
